exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-about-you-tsx": () => import("./../../../src/pages/about-you.tsx" /* webpackChunkName: "component---src-pages-about-you-tsx" */),
  "component---src-pages-account-selection-tsx": () => import("./../../../src/pages/account-selection.tsx" /* webpackChunkName: "component---src-pages-account-selection-tsx" */),
  "component---src-pages-id-verification-tsx": () => import("./../../../src/pages/id-verification.tsx" /* webpackChunkName: "component---src-pages-id-verification-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-requirements-tsx": () => import("./../../../src/pages/requirements.tsx" /* webpackChunkName: "component---src-pages-requirements-tsx" */),
  "component---src-pages-start-tsx": () => import("./../../../src/pages/start.tsx" /* webpackChunkName: "component---src-pages-start-tsx" */),
  "component---src-pages-terms-conditions-tsx": () => import("./../../../src/pages/terms-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-tsx" */)
}

